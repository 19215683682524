<template>
    <div class="modal-dialog onload-modal  modal-dialog-centered">
        <div class="modal-content">
            <div class="recent-bank-sec">
                    <div class="recent-bank">
                        <div class="bank-icon">
                            <img src="@/assets/images/right-icon.svg" alt="">
                        </div>
                        <div class="bank-con">
                            <h6>Success!</h6>
                            <span>{{ message }}</span>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
}
</script>